import axios from "axios";
import store from "../store";

const baseUrl = `${process.env.VUE_APP_DOMAIN}`;
const domainName = `${process.env.VUE_APP_DOMAIN}/api`;
var accessToken = localStorage.getItem("token");

const headers = {
  "Content-Type": "application/json",
  Authorization: accessToken ? `Bearer ${accessToken}` : null,
};

const Axios_Instance = axios.create({
  baseURL: baseUrl,
  headers: headers,
});

//  For inject fn before send request to server
Axios_Instance.interceptors.request.use(
  (config) => {
    if (!accessToken || !config.headers.Authorization) {
      // Set access token to header
      accessToken = localStorage.getItem("token");
      headers.Authorization = `Bearer ${accessToken}`;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error("[API - Axios_Instance] request error:", error);
    return error;
  }
);

//  For inject fn after send response to client
Axios_Instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.error(
      "[API - Axios_Instance] instance response error:",
      error.response
    );
    const { status } = error.response;
    var refreshToken = localStorage.getItem("refresh");
    if (status >= 500) {
      const first_error_msg = error.response.data.messages[0];
      store.dispatch("ToastModule/addToast", {
        type: "danger",
        title: "Error status " + status,
        body: first_error_msg.message || "Something Error",
      });
    } else if (status === 401 && refreshToken && accessToken) {
      axios
        .post(`${domainName}/auth/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.access);
          accessToken = localStorage.getItem("token");
          const first_error_msg = error.response.data.messages[0];
          store.dispatch("ToastModule/addToast", {
            type: "danger",
            title: "Error status " + status,
            body: first_error_msg.message + ". Please refresh your browsers.",
          });
          window.location.reload()
        })
        .catch((error) => {
          console.error(
            "[API - Axios_Instance] instance response error get refresh token:",
            error.response
          );
          if (error.response.status === 401) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + status,
              body: "Your session is expired. Please login again.",
            });
            localStorage.clear();
            setTimeout(() => {
              window.location.replace(
                `${baseUrl}/oauth/login/dsmauth/?callback=${window.location.origin}`
              );
            }, 500);
          }
        });
    } else {
      // TODO: Handle 404 redirect without change URL
      console.error(
        "[API - Axios_Instance] instance response error not handle for:",
        error.response.data
      );
    }
    return Promise.reject(error);
  }
);

// Base Axios_No_Auth_API
const Axios_No_Auth_API = {
  get(url, body, disableToast = false) {
    // console.log("M1 - Pass new GET no Auth API:", url, body, disableToast);
    return new Promise((resolve, reject) => {
      axios
        .get(`${domainName}${url}`, body)
        .then((response) => {
          // console.log("M1 - Response new GET no Auth API:", response);
          resolve(response);
        })
        .catch((error) => {
          const { response } = error;
          const errorMsg = response.data.detail || response.data.msg;
          if (!disableToast) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + response.status,
              body: errorMsg,
              delay: 7500,
            });
          }
          reject(response);
        });
    });
  },
};

// Base Axios_API with error toast alert
const Axios_API = {
  get(url, body, disableToast = false) {
    // console.log("M1 - Pass new GET API:", url, body, disableToast);
    return new Promise((resolve, reject) => {
      Axios_Instance.get(`${domainName}${url}`, body, {
        headers,
      })
        .then((response) => {
          // console.log("M1 - Response new GET API:", response);
          resolve(response);
        })
        .catch((error) => {
          const { response } = error;
          const errorMsg = response.data.detail || response.data.msg;
          if (!disableToast) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + response.status,
              body: errorMsg,
              delay: 7500,
            });
          }
          reject(response);
        });
    });
  },
  post(url, body, config, disableToast = false) {
    // console.log("M1 - Pass new POST API:", url, body, disableToast);
    const headerConfig = {
      ...headers,
      ...config,
    };
    return new Promise((resolve, reject) => {
      Axios_Instance.post(`${domainName}${url}`, body, {
        headerConfig,
      })
        .then((response) => {
          // console.log("M1 - Response new POST API:", response);
          if (!disableToast && response.status === 201 && response.data.msg) {
            store.dispatch("ToastModule/addToast", {
              type: "success",
              title: "Successful",
              body: response.data.msg,
              delay: 2400,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          const { response } = error;
          const errorMsg = response.data.detail || response.data.msg;
          if (!disableToast) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + response.status,
              body: errorMsg,
            });
          }
          reject(response);
        });
    });
  },
  patch(url, body, config, disableToast = false) {
    // console.log("M1 - Pass new PATCH API:", url, body, disableToast);
    const headerConfig = {
      ...headers,
      ...config,
    };
    return new Promise((resolve, reject) => {
      Axios_Instance.patch(`${domainName}${url}`, body, {
        headerConfig,
      })
        .then((response) => {
          // console.log("M1 - Response new PATCH API:", response);
          if (!disableToast && response.status === 202 && response.data.msg) {
            store.dispatch("ToastModule/addToast", {
              type: "success",
              title: "Update successful",
              body: response.data.msg,
              delay: 2400,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          const { response } = error;
          const errorMsg = response.data.detail || response.data.msg;
          if (!disableToast) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + response.status,
              body: errorMsg,
            });
          }
          reject(response);
        });
    });
  },
  delete(url, body, config, disableToast = false) {
    // console.log("M1 - Pass new DELETE API:", url, body, disableToast);
    const headerConfig = {
      ...headers,
      ...config,
    };
    return new Promise((resolve, reject) => {
      Axios_Instance.delete(`${domainName}${url}`, body, {
        headerConfig,
      })
        .then((response) => {
          // console.log("M1 - Response new DELETE API:", response);
          if (!disableToast && response.status === 204) {
            store.dispatch("ToastModule/addToast", {
              type: "success",
              title: "Delete successful",
              body: "Item has been deleted.",
              delay: 2400,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          const { response } = error;
          const errorMsg = response.data.detail || response.data.msg;
          if (!disableToast) {
            store.dispatch("ToastModule/addToast", {
              type: "danger",
              title: "Error status " + response.status,
              body: errorMsg,
            });
          }
          reject(response);
        });
    });
  },
};

export { Axios_API, Axios_No_Auth_API };
